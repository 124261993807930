<template>
  <div class="content">
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model:loading="loading"
        :finished="finished"
        finished-text="没有更多了"
        class="items"
        @load="queryList"
      >
        <van-cell v-for="(item, index) in list" :key="item.id">
          <div
            class="item"
            :class="{
              grey: item.is_overdue === '2' || item.is_overdue === '3',
            }"
            @click="toDetails(item)"
          >
            <div class="type">优惠券</div>
            <div class="prod">
              <div class="card">
                <span class="title">{{ item.coupon[0].title }}</span>
                <span class="desc">{{ item.coupon[0].desc }}</span>
                <span class="time">有效期至 {{ item.coupon[0].end_date }}</span>
              </div>
              <div class="money-info">
                <div class="money">
                  <span class="symbol">¥</span>
                  <span class="number">{{ item.coupon[0].money }}</span>
                </div>
                <span class="where"
                  >*满{{ item.coupon[0].full_money }}可用</span
                >
              </div>
            </div>
            <div class="remarks" v-if="item.is_overdue === '1'">
              <span class="use" @click="use(item)">进行中</span>
            </div>
            <div class="remarks" v-else>
              <span class="use">已结束</span>
            </div>
          </div>
          <div class="bord" v-if="(index + 1) % 5 === 0"></div>
        </van-cell>
      </van-list>
    </van-pull-refresh>
    <div class="add" @click="toAdd"></div>
  </div>
</template>

<script>
import { List, Cell, PullRefresh } from 'vant'
import Address from '@/components/Address'
import DatetimePicker from '@/components/DatetimePicker'

export default {
  components: {
    [Cell.name]: Cell,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    [Address.name]: Address,
    [DatetimePicker.name]: DatetimePicker,
    // AddActivity,
  },
  data() {
    return {
      finished: false,
      loading: false,
      refreshing: false,
      list: [],
      showAdd: false,
      info: {},
      queryParams: {
        page: 1,
        limit: 10,
      },
    }
  },
  methods: {
    getDetails(item) {
      this.info = item
      this.showDetails = true
    },
    onRefresh() {
      this.finished = false
      this.queryList()
    },
    toAdd() {
      this.$router.push('/activityAdd')
    },
    toDetails(activity) {
      // if(activity.is_overdue. ===2 ||activity.is_overdue. ===3){}
      this.$state.activity = activity
      this.$router.push({ name: 'details' })
    },
    queryList() {
      this.showAdd = false
      this.loading = true
      this.$http({
        url: '/merchant/merchantActivityIndex',
        method: 'get',
        data: this.$http.adornParams(this.queryParams),
      })
        .then(({ data }) => {
          this.list = data.data
          this.loading = false
          if (data.last_page > this.queryParams.page) {
            this.page++
          } else {
            this.refreshing = false
            this.finished = true
          }
        })
        .catch(() => {
          this.finished = true
          this.loading = false
          this.refreshing = false
        })
    },
  },
}
</script>
<style lang="less" scoped>
.van-cell {
  padding: 0;
}
.content {
  padding: 0 32px;
  .add {
    background: url(https://dev-iot-v2.oss-cn-shenzhen.aliyuncs.com/market/picture/20220609_154007_62a1a3d77738f.png);
    background-size: 100%;
    width: 44px;
    height: 44px;
    position: fixed;
    right: 20px;
    bottom: 10vh;
    cursor: pointer;
  }
  .bord {
    border-bottom: 2px dashed #d6dbe3;
    width: 85%;
    margin: 0 auto;
  }
  .grey {
    background: #c6c6c6 !important;
    .type {
      background: #c6c6c6 !important;
    }
    .remarks {
      .use {
        color: #c6c6c6 !important;
      }
    }
  }
  .close {
    width: 27px;
    height: 27px;
    opacity: 1;
    text-align: center;
    border: 2px solid #f1f2f5;
    color: #fff;
    border-radius: 23px;
    line-height: 27px;
    margin: 0 auto;
  }
  .line {
    border-bottom: 1px solid #f1f2f5;
    width: 100vw;
    margin: 0 -32px;
  }
  .items {
    padding: 8px 0;
    .item {
      // height: 120px;
      background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
      border-radius: 16px 16px 16px 16px;
      margin: 8px 0;
      padding: 5px;
      position: relative;
      .type {
        width: 39px;
        height: 15px;
        font-size: 13px;
        font-weight: 800;
        right: 2px;
        color: #ffffff;
        line-height: 15px;
        position: absolute;
        border: 2px solid #fff;
        transform: rotate(25deg);
        z-index: 11;
        background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
        top: 10px;
      }
      .prod {
        margin: 0 auto;
        height: 87px;
        display: flex;
        background: #ffffff;
        border-radius: 12px 12px 12px 12px;
        // padding-top: 5px;
        .money-info {
          // margin-right: 10px;
          position: absolute;
          line-height: 60px;
          right: 24px;
          .money {
            height: 60px;
            font-weight: bold;
            color: #242426;
            .symbol {
              font-size: 25px;
            }
            .number {
              font-size: 55px;
            }
          }

          .where {
            display: block;
            text-align: right;
            font-size: 10px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 12px;
            padding-right: 4px;
          }
        }
        .card {
          padding-top: 13px;
          padding-left: 14px;
          .title {
            width: 160px;
            text-align: left;
            display: block;
            height: 24px;
            font-size: 16px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.85);
            line-height: 24px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .desc {
            width: 160px;
            text-align: left;
            display: block;
            height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .time {
            width: 160px;
            text-align: left;
            display: block;
            height: 20px;
            font-size: 12px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.4);
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .remarks {
        height: 33px;
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        position: relative;
        text {
          line-height: 33px;
        }
        .desc {
          position: absolute;
          left: 14px;
        }
        .use {
          position: absolute;
          right: 24px;
          width: 61px;
          height: 19px;
          line-height: 19px;
          background: #ffffff;
          color: #f56b4e;
          border-radius: 51px 51px 51px 51px;
          top: 7px;
          text-align: center;
        }
      }
      .tip {
        font-size: 10px;
        font-weight: 400;
        color: #ffffff;
        padding: 5px 24px 10px 14px;
      }
    }
  }
}
</style>
